@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

:root {
    --color-primer: #FFFFFF;
    --color-aksen: #2A93D5;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.required{
    color: red;
}

/* sidebar */
aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    box-shadow: 3px 0px 20px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    background: var(--color-primer);
    z-index: 101;
}

aside h1 {
    margin-top: 55px;
    display: flex;
    align-items: center;
    font-family: 'Fredoka';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #2A93D5;
    gap: 5px;
}

aside ul {
    margin-top: 55px;
    list-style: none;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

aside ul li {
    border-radius: 10px;
    padding: 10px 0 10px 13px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    font-size: 15px;
    gap: 15px;
    color: #4B4B4B;
    cursor: pointer;
}

aside ul li:hover {
    background: #fff;
    color: var(--color-aksen);
}

aside ul li.active {
    background: #2A93D5;
    color: #FFFFFF;
}

aside a {
    text-decoration: none;
}

/*  */

.container-content {
    margin-left: 250px;
    /* width: 100%; */
}

nav {
    position: fixed;
    width: 100%;
    height: max-content;
    background: #ffffff;
    top: 0;
    z-index: 100;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 50px;
    box-shadow: none;
    transition: box-shadow 0.3s ease-in-out;
}

.navbar.shadow {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.20);
}

nav .navbar h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    /* line-height: 45px; */
    color: #2A93D5;
}

nav .navbar img {
    transform: translateX(-250px);
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

/* end navbar */

.main {
    margin-top: 128px;
    margin-bottom: 20px;
    padding: 0 50px;
}

.detail-profile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    /* padding: 30px; */
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 100;
    position: fixed;
    width: 350px;
    background: var(--color-primer);
    /* background: #4B4B4B; */
    /* background: #4B4B4B; */
    transform: translateX(350px);
    transition: transform 350ms ease-in-out;
    box-shadow: -3px 0px 20px rgba(0, 0, 0, 0.1)
}

.content-detail {
    z-index: 100;
    overflow-y: auto;
    /* overflow-x: hidden; */
    /* max-width: 290px; */
    padding: 0 30px;
}

.content-detail::-webkit-scrollbar {
    /* display: none; */
}

.navbar-detail {
    display: flex;
    z-index: 101;
    position: fixed;
    background: var(--color-primer);
    width: 100%;
    padding: 15px 0 15px 0;
}

.navbar-detail h2 {
    margin-left: 90px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 30px; */
    color: #2A93D5;
}

.detail-image-profile {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.detail-img-profile {
    width: 150px;
    height: 150px;
    border-radius: 100%;
}

.detail-profile .judul-detail {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* line-height: 22px; */
    color: #797979;
}

.detail-profile .value-detail {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 30px; */
    color: #4B4B4B;
    /* max-width: 290px; */
    margin: 5px 0 20px 0;
    overflow-wrap: break-word;
}

.con-btn-detail-profile {
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 101;
    background: var(--color-primer);
    padding: 10px 30px 30px 30px;
    /* margin-top: 150px; */
}

.con-btn-detail-profile button {
    display: flex;
    padding: 10px 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    align-items: center;
    gap: 15px;
    cursor: pointer;
}

.forget-password {
    background: #2A93D5;
    border-radius: 10px;
    border: none;
    color: var(--color-primer);
}

.logout {
    background: #FFC6C6;
    border-radius: 10px;
    border: none;
    color: #FF3F3F;
}

/*  */

.detail-notif {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    /* padding: 30px; */
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 100;
    position: fixed;
    width: 350px;
    background: var(--color-primer);
    /* background: #4B4B4B; */
    /* background: #4B4B4B; */
    transform: translateX(350px);
    transition: transform 350ms ease-in-out;
    box-shadow: -3px 0px 20px rgba(0, 0, 0, 0.1)
}

.content-detail-notif {
    z-index: 100;
    overflow-y: auto;
    /* overflow-x: hidden; */
    /* max-width: 290px; */
    padding: 0 30px;
}

.content-detail-notif::-webkit-scrollbar {
    /* display: none; */
}

.navbar-detail-notif {
    display: flex;
    z-index: 101;
    position: fixed;
    background: var(--color-primer);
    width: 100%;
    padding: 15px 0 15px 0;
}

.navbar-detail-notif h2 {
    margin-left: 90px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 30px; */
    color: #2A93D5;
}

.notif {
    display: flex;
    margin-top: 20px;
    gap: 20px;
    align-items: center;
    font-family: 'Poppins';
}

.day {
    font-size: 15px;
    font-weight: 400;
    color: #797979;
    margin-top: 100px;
    font-family: 'Poppins';
}

.icon-notif {
    width: 60px;
    height: 60px;
    background-color: #D8F0FF;
    color: var(--color-aksen);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.name-notif {
    font-size: 20px;
    font-weight: 500;
    color: #4B4B4B;
}

.teacher {
    font-size: 15px;
    font-weight: 400;
    color: #797979;
}

/*  */

/* detail-forget */

#popup-forget {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 200;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
}

.detail-forget-password {
    display: none;
    width: 547px;
    background: #ffffff;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.navbar-detail-forget {
    display: flex;
    justify-content: space-between;
}

.divKosong {
    width: 30px;
}

.navbar-detail-forget h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin: auto;
    /* margin-left: 134px; */
    color: #2A93D5;
    /* margin-bottom: 20px; */
    /* line-height: 30px; */
}

.judul-form {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 22px; */
    color: #797979;
    margin-bottom: 5px;
    margin-top: 20px;
}

.con-form-password {
    display: flex;
    align-items: center;
    border: 1px solid #C4C4C4;
    padding: 0 20px;
}

.con-form-password input {
    border: none;
}

.input-password {
    width: 100%;
    height: 100%;
    padding: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
}

.input-password:focus {
    outline: none;
}

.btn-simpan {
    margin-top: 40px;
    background: var(--color-aksen);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: var(--color-primer);
    border: none;
    padding: 9px 0;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
}

.btn-mata {
    background: transparent;
    border: none;
    color: var(--color-primer);
    margin: 0;
    padding: 0;
    cursor: pointer;
}

@keyframes slide-down {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slide-up {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%);
    }
}

/* end detail forget */

/* popup logout */
#popup-logout {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 200;
    display: none;
    justify-content: center;
    align-items: center;
    animation-fill-mode: forwards;
}

.img-logout {
    max-width: 230px;
    height: auto;
}

.detail-logout {
    background: #FFFFFF;
    border-radius: 20px;
    width: 480px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.image-logout {
    display: flex;
    justify-content: center;
}

.con-btn-logout {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-top: 40px;
}

.desc-logout {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* line-height: 22px; */
    color: #797979;
    text-align: center;
}

.btn-batal {
    background: #D8F0FF;
    border-radius: 10px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 22px; */
    color: #2A93D5;
    width: 100%;
    cursor: pointer;
}

.btn-batal:hover {
    background: #2A93D5;
    color: var(--color-primer);
    transition: 0.25s ease-in-out;
}

.btn-keluar {
    background: #FFC6C6;
    border-radius: 10px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 22px; */
    color: #FF3F3F;
    width: 100%;
    padding: 14px 0;
    cursor: pointer;
}

.btn-keluar:hover {
    background: #FF3F3F;
    color: var(--color-primer);
    transition: 0.25s ease-in-out;
}

/* end popup Delete */

/* popup success */

#popup-success, #popup-success-addCode, #popup-success-ChangesPass {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 200;
    display: none;
    justify-content: center;
    align-items: center;
    animation-fill-mode: forwards;
    background-color: rgba(0, 0, 0, 0.25);
}

.img-success {
    max-width: 230px;
    height: auto;
}

.detail-success, .detail-success-addCode, #detail-success-ChangesPass {
    background: #FFFFFF;
    border-radius: 20px;
    width: 480px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.image-success {
    display: flex;
    justify-content: center;
}

.desc-success {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* line-height: 22px; */
    color: #797979;
    text-align: center;
}

.btn-success, .btn-out {
    background: #D8F0FF;
    border-radius: 10px;
    border: none;
    margin-top: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 22px; */
    color: #2A93D5;
    width: 100%;
    padding: 14px 0;
    cursor: pointer;
}

.btn-success:hover, .btn-out:hover {
    background: #2A93D5;
    color: var(--color-primer);
    transition: 0.25s ease-in-out;
}

/* end popup success */

/* popup Failed */

#popup-Failed, #popup-Failed-addCode, #popup-Failed-Import, #popup-Failed-NoData, #popup-Failed-ChangesPass {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 200;
    display: none;
    justify-content: center;
    align-items: center;
    animation-fill-mode: forwards;
    background: rgba(0, 0, 0, 0.25);
}

.img-Failed {
    max-width: 230px;
    height: auto;
}

.detail-Failed, #detail-Failed-ChangesPass {
    background: #FFFFFF;
    border-radius: 20px;
    width: 480px;
    display: flex;
    flex-direction: column;
    padding: 30px;
}

.image-Failed {
    display: flex;
    justify-content: center;
}

.desc-Failed {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* line-height: 22px; */
    color: #797979;
    text-align: center;
}

.btn-Failed {
    background: #ffb0b0;
    border-radius: 10px;
    border: none;
    margin-top: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 22px; */
    color: #ff0e0e;
    width: 100%;
    padding: 14px 0;
    cursor: pointer;
}

.btn-Failed2 {
    background: #ffb0b0;
    border-radius: 10px;
    border: none;
    margin-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 22px; */
    color: #ff0e0e;
    width: 100%;
    padding: 14px 0;
    cursor: pointer;
}

.btn-Failed:hover, .btn-Failed2:hover {
    background: #ee3a3a;
    color: var(--color-primer);
    transition: 0.25s ease-in-out;
}

/* end popup Failed */

/* popup Delete */
#popup-Delete {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 200;
    display: none;
    justify-content: center;
    align-items: center;
    animation-fill-mode: forwards;
    background: rgba(0, 0, 0, 0.25);
}

.img-Delete {
    max-width: 230px;
    height: auto;
}

.detail-Delete {
    background: #FFFFFF;
    border-radius: 20px;
    width: 480px;
    display: none;
    flex-direction: column;
    padding: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.image-Delete {
    display: flex;
    justify-content: center;
}

.con-btn-Delete {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-top: 40px;
}

.desc-Delete {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* line-height: 22px; */
    color: #797979;
    text-align: center;
}

.btn-batal {
    background: #D8F0FF;
    border-radius: 10px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 22px; */
    color: #2A93D5;
    width: 100%;
    cursor: pointer;
}

.btn-batal:hover {
    background: #2A93D5;
    color: var(--color-primer);
    transition: 0.25s ease-in-out;
}

.btn-delete {
    background: #FFC6C6;
    border-radius: 10px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 22px; */
    color: #FF3F3F;
    width: 100%;
    padding: 14px 0;
    cursor: pointer;
}

.btn-delete:hover {
    background: #FF3F3F;
    color: var(--color-primer);
    transition: 0.25s ease-in-out;
}

/* end popup Delete */

/* pop up kode */

#popup-Kode {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 200;
    display: none;
    justify-content: center;
    align-items: center;
    animation-fill-mode: forwards;
    background: rgba(0, 0, 0, 0.25);
}

.detail-Kode {
    background: #FFFFFF;
    border-radius: 20px;
    width: 480px;
    display: none;
    flex-direction: column;
    padding: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.navbar-detail-Kode {
    display: flex;
    justify-content: space-between;
}

.navbar-detail-Kode h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #2A93D5;
}

.inputGuru {
    width: 100%;
    height: 100%;
    padding: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    color: #000000;
}

.inputGuru:focus {
    outline: none;
}

.btn-sumbitKode {
    margin-top: 40px;
    background: var(--color-aksen);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: var(--color-primer);
    border: none;
    padding: 9px 0;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
}

.btn-sumbitKode:hover {
    background: #1D77AF;
    transition: 0.10s ease-in-out;
}

/* end pop up kode */

/* pop up detailGuru */

.popup-detailGuru {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 200;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.25);
}

.detail-popup-detailGuru {
    width: 547px;
    display: none;
    max-width: 450px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.con-popup-detailGuru {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    margin: 20px 30px 30px 30px;
    height: 460px;
}

.navbar-detail-detailGuru {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 10px 30px;
}

.navbar-detail-detailGuru h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #2A93D5;
}

.img-detailGuru {
    margin: 0 auto;
    max-width: 150px;
}

.image-detailGuru {
    min-width: 150px;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 100%;
}

.con-popup-detailGuru h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* line-height: 22px; */
    margin-bottom: 5px;
    color: #797979;
}

.con-popup-detailGuru p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 30px; */
    margin-bottom: 20px;
    color: #4B4B4B;
}

.con-popup-detailGuru .con-kode-detailGuru, .con-popup-detailGuru .con-mengajar-detailGuru, .con-popup-detailGuru .con-mengajarkelas-detailGuru {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
    /* flex-direction: column; */
    gap: 15px;
    width: min-content;
    margin-bottom: 20px;
}

.con-popup-detailGuru .con-kode-detailGuru p, .con-popup-detailGuru .con-mengajar-detailGuru p, .con-popup-detailGuru .con-mengajarkelas-detailGuru p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: var(--color-aksen);
    margin-bottom: 0;
}

.mengajar-detailGuru, .kode-detailGuru, .mengajarKelas-detailGuru {
    background: #D8F0FF;
    border-radius: 30px;
    padding: 3px 17px;
    width: max-content;
}

/* end pop up detailGuru */

/* pop up detailMurid */

.popup-detailMurid {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 200;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.25);
}

.detail-popup-detailMurid {
    width: 547px;
    display: none;
    max-width: 450px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.con-popup-detailMurid {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    margin: 20px 30px 30px 30px;
    height: 460px;
}

.navbar-detail-detailMurid {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 10px 30px;
}

.navbar-detail-detailMurid h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #2A93D5;
}

.img-detailMurid {
    margin: 0 auto;
    max-width: 150px;
}

.image-detailMurid {
    min-width: 150px;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 100%;
}

.con-popup-detailMurid h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* line-height: 22px; */
    margin-bottom: 5px;
    color: #797979;
}

.con-popup-detailMurid p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 30px; */
    margin-bottom: 20px;
    color: #4B4B4B;
}

/* end popup detailMurid */

/* notfound */
.notfound {
    align-items: center;
    text-align: center;
    padding-top: 280px;
    background-color: #1a253c;
    height: 100vh;
}

.notfound h1 {
    color: #ee3a3a;
    font-size: 100px;
    text-shadow: 0px 0px 20px #ee3a3a;
}

.notfound marquee {
    font-size: 18px;
    text-align: center;
    color: #fff;
    text-shadow: 0px 0px 10px white;
}

/* footer notfound page*/
.footer {
    color: #fff;
    bottom: 0;
    position: relative;
    opacity: 50%;
}

/* end footer notfound page */
/* end notfound */

/* loading card */

:root {
    --hue: 223;
    --bg: hsl(var(--hue), 10%, 90%);
    --fg: hsl(var(--hue), 10%, 10%);
    /* font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320)); */
}

.popup-loading {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 400;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.25);
}

.body-loading {
    /* background: var(--bg); */
    color: var(--fg);
    font: 1em/1.5 sans-serif;
    /* height: 20vh; */
    display: none;
    place-items: center;
}

.pl {
    display: block;
    width: 6.25em;
    height: 6.25em;
}

.pl__ring, .pl__ball {
    animation: ring 2s ease-out infinite;
}

.pl__ball {
    animation-name: ball;
}

/* Dark theme  */
@media (prefers-color-scheme: dark) {
    :root {
        --bg: hsl(var(--hue), 10%, 10%);
        --fg: hsl(var(--hue), 10%, 90%);
    }
}

/* Animation */
@keyframes ring {
    from {
        stroke-dasharray: 0 257 0 0 1 0 0 258;
    }

    25% {
        stroke-dasharray: 0 0 0 0 257 0 258 0;
    }

    50%, to {
        stroke-dasharray: 0 0 0 0 0 515 0 0;
    }
}

@keyframes ball {
    from, 50% {
        animation-timing-function: ease-in;
        stroke-dashoffset: 1;
    }

    64% {
        animation-timing-function: ease-in;
        stroke-dashoffset: -109;
    }

    78% {
        animation-timing-function: ease-in;
        stroke-dashoffset: -145;
    }

    92% {
        animation-timing-function: ease-in;
        stroke-dashoffset: -157;
    }

    57%, 71%, 85%, 99%, to {
        animation-timing-function: ease-out;
        stroke-dashoffset: -163;
    }
}

.body-loadingDetail {
    background: #FFFFFF;
    /* height: 200px; */
    width: 220px;
    height: 70px;
    border-radius: 10px;
    display: none;
    place-items: center;
}

.body-loadingDetail p {
    color: var(--color-aksen);
    font-weight: 500;
    font-family: poppins;
    font-size: 16px;
}

@keyframes load {
    0% {
        opacity: 0.08;
        /*         font-size: 10px; */
        /* 				font-weight: 400; */
        filter: blur(5px);
        letter-spacing: 3px;
    }

    100% {
        /*         opacity: 1; */
        /*         font-size: 12px; */
        /* 				font-weight:600; */
        /* 				filter: blur(0); */
    }
}

.animate-loadingDetail {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height:100%; */
    margin: auto;
    /* 	width: 350px; */
    /* 	font-size:26px; */
    font-family: Helvetica, sans-serif, Arial;
    animation: load 1.2s infinite 0s ease-in-out;
    animation-direction: alternate;
    text-shadow: 0 0 1px white;
}

/* end */