@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

:root {
    --color-primer: #FFFFFF;
    --color-aksen: #2A93D5;
    --color-sekunder: #D8F0FF;
}

.header-pageKbm-Guru {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
}

.content-pageKbm-Guru {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; 
    width: 100%;
    margin-top: 30px;
}

.card-pageKbm-Guru-skeleton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    width: 100%;
    min-height: 102.5px;
    color: var(--color-primer);
    background: #f5f5f5;
    /* Warna latar belakang skeleton */
    background-image: linear-gradient(90deg,
            #f5f5f5 25%,
            #e8e8e8 37%,
            #f5f5f5 63%);
    /* Animasi gradien skeleton */
    background-size: 200% 100%;
    /* Ukuran gradien skeleton */
    animation: skeleton-loading 1.5s infinite linear;
    /* Animasi skeleton berjalan selamanya */
}

@keyframes skeleton-loading {
    0% {
        background-position: -200% 0;
        /* Mulai dari posisi luar layar */
    }

    100% {
        background-position: 200% 0;
        /* Pindah ke posisi luar layar sebaliknya */
    }
}

.card-pageKbm-Guru {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 10px;
}

.card-pageKbm-Guru-left {
    display: flex;
    align-items: center;
    gap: 15px;
}

.img-pageKbm-Guru {
    color: var(--color-aksen);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #D8F0FF;
    border-radius: 10px;
}

.desc-pageKbm-Guru {
    color: var(--color-aksen);
    font-family: 'Poppins';
}

.title-pageKbm-Guru {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.kelas-pageKbm-Guru {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
}