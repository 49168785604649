@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

:root {
    --color-primer: #FFFFFF;
    --color-aksen: #2A93D5;
    --color-sekunder: #D8F0FF;
}

.header-AddAssets {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-add-AddAssets {
    display: flex;
    background: var(--color-aksen);
    color: var(--color-primer);
    border: none;
    padding: 13px 35px;
    width: 200px;
    height: 50px;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    gap: 5px;
}

.btn-add-AddAssets:hover {
    background: #1D77AF;
}

.header-AddAssets-left {
    display: flex;
    gap: 15px;
}

.header-AddAssets .header-AddAssets-left .btn-add-AddAssets p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 22px; */
    color: #FFFFFF;
}

.detail-jumlah-AddAssets {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #2A93D5;
}

/*  */

/*  */

.content-AddAssets {
    margin-top: 30px;
}

.con-card-AddAssets {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

@media only screen and (max-width: 740px) {
    .con-card-AddAssets {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media only screen and (min-width: 1020px) {
    .con-card-AddAssets {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 1180px) {
    .con-card-AddAssets {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (min-width: 1640px) {
    .con-card-AddAssets {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (min-width: 2030px) {
    .con-card-AddAssets {
        grid-template-columns: repeat(5, 1fr);
    }
}

.card-AddAssets {
    border-radius: 20px;
    max-width: 405px;
    position: relative;
}

.image-card-AddAssets {
    width: 100%;
    height: auto;
}

.content-card-AddAssets {
    display: flex;
    position: absolute;
    top: 0;
    padding-left: 30px;
    width: 100%;
}

/* cardAddAssets-skeleton */

@keyframes skeleton-loading {
    0% {
        background-position: -200% 0;
        /* Mulai dari posisi luar layar */
    }

    100% {
        background-position: 200% 0;
        /* Pindah ke posisi luar layar sebaliknya */
    }
}

.cardAddAssets-skeleton {
    border-radius: 20px;
    max-width: 405px;
    width: 100%;
    height: 220px;
    background: #f5f5f5;
    /* Warna latar belakang skeleton */
    background-image: linear-gradient(90deg,
            #f5f5f5 25%,
            #e8e8e8 37%,
            #f5f5f5 63%);
    /* Animasi gradien skeleton */
    background-size: 200% 100%;
    /* Ukuran gradien skeleton */
    animation: skeleton-loading 1.5s infinite linear;
    /* Animasi skeleton berjalan selamanya */
}

.hover-card-AddAssets {
    cursor: pointer;
    position: absolute;
    background: rgba(0, 0, 0, 0.25);
    opacity: 1;
    transition: 0.5s;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hover-card-AddAssets .con-btn-card-AddAssets {
    display: flex;
    gap: 15px;
}

.hover-card-AddAssets .con-btn-card-AddAssets .btn-delete-AddAssets {
    background: #BC2525;
    color: #fff;
}

.hover-card-AddAssets .con-btn-card-AddAssets .btn-delete-AddAssets:hover {
    background: #7F1B1B;
}

.hover-card-AddAssets .con-btn-card-AddAssets button {
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    /* padding: 13px; */
    cursor: pointer;
}