@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap");

:root {
  --color-primer: #ffffff;
  --color-aksen: #2a93d5;
}

.content-task {
  margin-top: 30px;
}

.header-task {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-task-left {
  display: flex;
  gap: 15px;
}

.header-task .header-task-left .btn-add-guru p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 22px; */
  color: #FFFFFF;
}

.detail-jumlah-guru {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #2A93D5;
}

/* Styling untuk dropdown */
select {
  width: 250px;
  height: 50px;
  padding: 10px;
  font-size: 15px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  border: 1px solid #C4C4C4;
  appearance: none;
  padding: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url('https://api.iconify.design/ic/round-navigate-next.svg?color=%232a93d5&width=30');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}

/* Styling untuk opsi yang dipilih */
option {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #4B4B4B;
  background-color: var(--color-primer);

}

option[selected] {
  /* outline: none; */
  /* font-weight: bold; */
}

/*  */

.search-box {
  display: flex;
  align-items: center;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 8px;
  height: 50px;
}

.search-box input[type="text"] {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 10px;
}

.search-box button[type="submit"] {
  background-color: #008CBA;
  color: #fff;
  border: none;
  border-radius: 5px;
  /* padding: 8px; */
  width: 40px;
  height: 40px;
  margin-left: 8px;
  cursor: pointer;
}

/*  */

.card-task {
  padding: 15px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid #e3e3e3;
  background: var(--color-primer);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}

.indiecator-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon-indie-information {
  /* background: rgba(221, 221, 221, 1); */
  /* padding: 20px; */
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
}

.title-indie-information {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  /* line-height: 22px; */
  color: #4b4b4b;
}

.value-indie-information {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 30px; */
  color: #797979;
}

.indiecator-right {
  display: flex;
  gap: 50px;
}

.icon-navigate {
  color: var(--color-aksen);
}

.time-upload {
  margin-right: 80px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #797979;
}

.deadline-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-right: 50px;
}
