@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

:root {
    --color-primer: #FFFFFF;
    --color-aksen: #2A93D5;
    --color-sekunder: #D8F0FF;
}

.content-jadwalKBM-Guru {
    margin-top: 30px;
    padding: 17px;
    /* background: #FFFFFF; */
    border: 1px solid #C4C4C4;
    border-radius: 10px;
}

.valueDay {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #4B4B4B;

}

.con-card-jadwalKBM-Guru {
    padding: 10px 5px;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.card-jadwalKBM-Guru {
    display: flex;
    justify-content: space-between;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    padding: 10px 20px;
    align-items: center;
}

.card-jadwalKBM-Guru-skeleton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    width: 100%;
    min-height: 102.5px;
    color: var(--color-primer);
    background: #f5f5f5;
    /* Warna latar belakang skeleton */
    background-image: linear-gradient(90deg,
            #f5f5f5 25%,
            #e8e8e8 37%,
            #f5f5f5 63%);
    /* Animasi gradien skeleton */
    background-size: 200% 100%;
    /* Ukuran gradien skeleton */
    animation: skeleton-loading 1.5s infinite linear;
    /* Animasi skeleton berjalan selamanya */
}

@keyframes skeleton-loading {
    0% {
        background-position: -200% 0;
        /* Mulai dari posisi luar layar */
    }

    100% {
        background-position: 200% 0;
        /* Pindah ke posisi luar layar sebaliknya */
    }
}

.card-jadwalKBM-Guru-left {
    display: flex;
    align-items: center;
    width: 35%;
    justify-content: space-between;
}

.id-jadwalKBM-Guru {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #4B4B4B;
}

.icon-jadwalKBM-Guru {
    background: var(--color-sekunder);
    color: var(--color-aksen);
    padding: 10px;
    border-radius: 100%;
}

.dataKelas-jadwalKBM-Guru {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: var(--color-aksen);
}

.dataMapel-jadwalKBM-Guru {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #4B4B4B;
}

.hourValue-jadwalKBM-Guru {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    color: #4B4B4B;
}