@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

:root {
    --color-primer: #FFFFFF;
    --color-aksen: #2A93D5;
    --color-sekunder: #D8F0FF;
}

.header-murid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-add-murid {
    display: flex;
    background: var(--color-aksen);
    color: var(--color-primer);
    border: none;
    padding: 13px 35px;
    width: 200px;
    height: 50px;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    gap: 5px;
}

.btn-add-murid:hover {
    background: #1D77AF;
}

.header-murid-left {
    display: flex;
    gap: 15px;
}

.header-murid .header-murid-left .btn-add-murid p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 22px; */
    color: #FFFFFF;
}

.detail-jumlah-murid {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #2A93D5;
}

/* Styling untuk dropdown */
select {
    width: 250px;
    padding: 10px;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #C4C4C4;
    appearance: none;
    padding: 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url('https://api.iconify.design/ic/round-navigate-next.svg?color=%232a93d5&width=30');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
}

/* Styling untuk opsi yang dipilih */
option {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* line-height: 22px; */
    color: #4B4B4B;
    background-color: var(--color-primer);

}

option[selected] {
    /* outline: none; */
    /* font-weight: bold; */
}

/*  */

.search-box {
    display: flex;
    align-items: center;
    width: 300px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
}

.search-box input[type="text"] {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 10px;
}

.search-box button[type="submit"] {
    background-color: #008CBA;
    color: #fff;
    border: none;
    border-radius: 5px;
    /* padding: 8px; */
    width: 40px;
    height: 40px;
    margin-left: 8px;
    cursor: pointer;
}

/*  */

/* TABLE */
.container-table {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    margin-top: 30px;
}

.content-table-murid {
    width: 100%;
    background-color: white;
    padding: 1rem 1.5rem;
    border: 1px solid #EBF0FE;
    border-top: none;
    border-collapse: collapse;
    position: relative;
}

.content-table-murid thead{
    width: 100%;
    border-bottom: 1px solid #000;
}

.content-table-murid thead th {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    padding: 1.5rem 0;
    padding-left: -10px;
    color: #797979;
}

.content-table-murid thead th:nth-child(1) {
    padding-left: 1.5rem;
}

.content-table-murid tbody tr {
    width: 100%;
}

.content-table-murid tbody:hover tr:hover {
    background-color: var(--color-sekunder);
}

/* .content-table-murid tbody tr:nth-child(odd) {
    background-color: #efefefb6;
} */

.content-table-murid td {
    font-size: 15px;
    color: #797979;
}

.content-table-murid .row-img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem 1.5rem;
}

.content-table-murid .row-img img {
    width: 35px;
    height: 35px;
    margin-right: 1rem;
    border-radius: 50%
}

.content-table-murid tbody tr:hover .jurusan {
    background: #2A93D5;
    color: white;
}

.jurusan {
    width: 100px;
    height: 30px;
    font-size: 15px;
    background: #D8F0FF;
    color: #2A93D5;
    display: grid;
    place-items: center;
    border-radius: 5px;
}

.popup-menu-muridAdmin {
    display: none;
    position: absolute;
    background-color: #fff;
    z-index: 1;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    transform: translateX(-100%);
}

.popup-menu-muridAdmin.active {
    display: block;
}


.popup-menu-muridAdmin ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 9px;
}

.popup-menu-muridAdmin ul li {
    padding-right: 30px;
    
}

.popup-menu-muridAdmin ul li a {
    display: block;
    padding: 10px;
    color: #333;
    text-decoration: none;
}

#popup-menu-muridAdmin li:nth-child(1):hover {
    background-color: #D8F0FF;
    border-radius: 9px 9px 0px 0px;
}

#popup-menu-muridAdmin li:nth-child(2):hover {
    background-color: #D8F0FF;
}

#popup-menu-muridAdmin li:nth-child(3):hover {
    background-color: #FFD8D8;
    border-radius: 0px 0px 9px 9px;
}

.dataNotFound{
    text-align: center;
    padding: 50px 0;
    font-size: 20px;
    color: #333
}

.tdImg{
    padding: 10px;
}