.content-jadwal{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 100px;
}

.navigate-icon{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: var(--color-primer);
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.25);
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    /* masih pake margin */
    margin-left: 275px;
    margin-top: 170px; 
}

.title-jadwal{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 150%; */
    color: #fff;
    /* margin-top: 15px; */
}

.hari-jadwal{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 5500;
    font-size: 30px;
    color: #fff;
}

.card-jadwal{
    width: 100%;
    border-radius: 20px;
    padding: 30px 0 25px 25px;
    display: flex;
    height: 350px; 
}
