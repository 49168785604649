.btn-export{
    background-color: #2a93d5;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    padding: 0 1rem;
}

.btn-export:hover {
    background: #1D77AF;
}