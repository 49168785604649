@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

:root {
    --color-primer: #FFFFFF;
    --color-aksen: #2A93D5;
}

.header-dashboard {
    background: var(--color-aksen);
    width: 100%;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 87px 0 87px 70px;
    display: flex;
}

.head-left {
    display: flex;
    flex-direction: column;
    max-width: 475px;
}

.intro-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    /* line-height: 45px; */
    color: #FFFFFF;
}

.name-admin {
    background: var(--color-primer);
    padding: 3px 14px;
    color: var(--color-aksen);
    border-radius: 10px;
}

.desc-head {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    /* line-height: 150%; */
    color: #FFFFFF;
    margin-top: 15px;
}

.kotak1 {
    background-color: #58AFE5;
}

/*  */

/*  */

.con-content-admin{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 30px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    .con-content{
        grid-template-columns: repeat(1, 1fr);
    }
}

.content-indiecator {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 20px;
    border-radius: 20px;
}

.icon-indie {
    /* color: #EB55A3; */
    background: var(--color-primer);
    padding: 15px;
    border-radius: 10px;
}

.title-indie {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* line-height: 22px; */
    color: #FFFFFF;
}

.value-indie {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* line-height: 30px; */
    color: #FFFFFF;
}