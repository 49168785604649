.con-content-material{
    border: 1px solid #c4c4c4;
    border-radius: 20px;
    padding: 30px;
    font-family: 'Poppins';
}

.content-material{
    /* border-radius: 20px; */
    /* height: 350px; */
    display: flex;
    /* padding: 30px; */
    justify-content: space-between;
}

.content-material-left {
    display: flex;
    align-items: center;
    gap: 20px;
}

.icon-material {
    background: #D8F0FF;
    /* padding: 20px; */
    width: 70px;
    height: 70px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 10px;
}

.date-upload{
    font-size: 15px;
    color: #797979;
    font-weight: 500;
}

.name-material{
    font-size: 20px;
    color: var(--color-aksen);
    font-weight: 500;
}

.content-material-right{
    display: flex;
    gap: 50px;
}

.desc-content-material{
    margin-top: 30px;
    color: #4b4b4b;
}

.file-material{
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    margin-top: 40px;
    padding: 10px;
    width: 350px;
}

.file-material iframe{
    border-radius: 5px;
}

.switch-container {
    width: max-content;
    display: flex;
    gap: 2px;
    margin-top: 40px;
    padding: 5px;
    border-radius: 10px;
    background-color: var(--color-sekunder);
}

#btn-materiKbm, #btn-tugasKbm {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    padding: 5px 43px;
    border-radius: 5px;
    background-color: var(--color-sekunder);
    color: var(--color-aksen);
    border: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

#btn-materiKbm.activeDetailKbm, #btn-tugasKbm.activeDetailKbm {
    background-color: var(--color-aksen);
    color: var(--color-primer);
    /* transition-delay: 0.9s; */
}

.con-switch-subject{
    display: flex;
    gap: 15px;
    /* justify-content: space-between; */
}