@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

:root {
    --color-primer: #FFFFFF;
    --color-aksen: #2A93D5;
    --color-sekunder: #D8F0FF;
}

a{
    text-decoration: none;
}

.con-card-detailMateri {
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 30px;
}

.header-card-detailMateri {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.left-header-card-detailMateri {
    display: flex;
    gap: 20px;
    align-items: center;
}

.icon-header-card-detailMateri {
    background: #D8F0FF;
    border-radius: 10px;
    padding: 15px;
    color: var(--color-aksen);
}

.title-header-card-detailMateri {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: var(--color-aksen);
}

.guru-header-card-detailMateri {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #797979;
}

.right-header-card-detailMateri {
    position: absolute;
    display: flex;
    gap: 30px;
    top: 0;
    right: 0;
    align-items: center;
}

.date-header-card-detailMateri {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #797979;
}

.icon-options {
    color: var(--color-aksen);
}

.desc-card-detailMateri {
    margin-top: 30px;
    margin-bottom: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    color: #4B4B4B;
}

.value-fileOrlink {
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    padding: 10px 60px 10px 10px;
    gap: 20px;
    width: max-content;
}

#value-fileOrlink {
    display: flex;
    align-items: center;
}

.value-fileOrlink iframe {
    width: 180px;
    height: 120px;
    border-radius: 10px;
}

.title-fileOrlink {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #4B4B4B;
}

.link-detailMateri {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #797979;
}

.link-detailMateri span{
    color: var(--color-aksen);
}