@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap");

:root {
  --color-primer: #ffffff;
  --color-aksen: #2a93d5;
}

.content-promise-counseling {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 100px;
}

.card-profile-teacher-bk {
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  /* padding: 56px 90px 56px; */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 350px;
  /* display: flex; */
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}

.card-calendar {
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  /* padding: 56px 90px 56px; */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 350px;
  /* display: flex; */
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}

.card-date-time {
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  padding: 15px;
  /* justify-content: center; */
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  /* height: 350px; */
  /* display: flex; */
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}

.name-bk-teacher {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  font-family: "Poppins";
  color: #4b4b4b;
  margin-top: 15px;
}

.teach {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  font-family: "Poppins";
  color: #797979;
}

.img-bk-teacher {
  width: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.gif-calendar {
  width: 220px;
  margin-top: -50px;
}

.text-desc-date {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  font-family: "Poppins";
  color: #4b4b4b;
  width: 233px;
  text-align: center;
  margin-top: -50px;
}

.date-selected {
  font-family: "Poppins";
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: var(--color-aksen);
  margin-top: 5px;
}

.text-select-time {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  color: #4b4b4b;
  margin-top: 20px;
}

/* calendar */
.calendar {
  font-family: "Poppins";
}

.react-calendar {
  padding: 15px;
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #4b4b4b;
  border-radius: 10px;
  border: none;
  /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); */
  font-family: "Poppins";
  line-height: 1.125em;
}

.react-calendar__navigation button {
  color: #2a93d5;
  min-width: 30px;
  background: none;
  font-size: 20px;
  margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f3f3f3;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #2a93d5;
  border-radius: 6px;
}
.react-calendar__tile--now {
  background: #2a93d5;
  border-radius: 6px;
  font-weight: bold;
  color: #2a93d5;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #2a93d5;
  border-radius: 6px;
  font-weight: bold;
  color: #2a93d5;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  background: #2a93d5;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2a93d5;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--range {
  background: #f8f8fa;
  color: #2a93d5;
  border-radius: 0;
}
.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #2a93d5;
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: 5px;
  /* border-top-right-radius: 6px; */
  /* border-bottom-right-radius: 6px; */
  background: #2a93d5;
  color: white;
}
/* end calendar */

/* card date time */
.time-promise-counseling {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 15px;
}

.btn-time-counseling {
  background: #d9d9d9;
  color: #797979;
  border: none;
  width: 60px;
  height: 30px;
  border-radius: 5px;
  margin: 10px 5px;
  font-family: "Poppins";
  cursor: pointer;
}

.selected {
  background: #f67575;
  color: var(--color-primer);
}

.color-status-promise-counseling {
  display: flex;
  margin-top: 15px;
}

.status-not-selected {
  display: flex;
}

.status-selected {
  display: flex;
}

.rectangle-not-selected {
  background: #d9d9d9;
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.rectangle-selected {
  background: #f67575;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-left: 12px;
}

.text-status-not-selected {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #797979;
  margin-left: 5px;
}

.text-status-selected {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #797979;
  margin-left: 5px;
}

.dropdown-location-counseling {
  margin-top: 40px;
}

.dropdown-counseling {
  width: 100%;
}

.form-topic {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 8px;
  height: 50px;
}

.form-topic input[type="text"] {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 10px;
}

.btn-submit-promise-counseling {
  width: 100%;
  height: 40px;
  background: var(--color-aksen);
  margin-top: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: var(--color-primer);
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins";
}

.btn-submit-promise-counseling:hover {
  background: #1d77af;
}
/* end card date time */

.btn-submit-promise-counseling:hover {
  background: #1d77af;
}
/* end card date time */