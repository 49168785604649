@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

:root {
    --color-primer: #FFFFFF;
    --color-aksen: #2A93D5;
    --color-sekunder: #D8F0FF;
}

.main-pageAssets{
    padding: 0 50px;
}

.content-pageAssets {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.con-content-pageAssets {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img-pageAssets {
    max-width: 300px;
}

.imagepageAssets {
    width: 100%;
    height: auto;
}

.btn-pageAssets {
    display: flex;
    gap: 10px;
    background: #2A93D5;
    border-radius: 10px;
    color: var(--color-primer);
    padding: 13px 40px;
    cursor: pointer;
}

.btn-pageAssets:hover {
    background: #1D77AF;
}