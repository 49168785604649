.content-subject {
  display: flex;
  border-radius: 20px;
  padding-left: 30px;
  width: 70%;
  justify-content: space-between;
}

.content-subject-left {
  /* width: 720px;
    height: 200px; */
  margin-top: 30px;
}

.name-subject {
  color: var(--color-primer);
  font-size: 30px;
  font-weight: 500;
}

.name-teacher {
  color: var(--color-primer);
  font-size: 20px;
  font-weight: 400;
}

.content-subject-2 {
  display: flex;
  padding: 50px 0px 50px 30px;
  width: 30%;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  gap: 20px;
}

.img-subject-2 {
  border-radius: 100%;
  width: 100px;
}

.name-teacher-2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #4b4b4b;
}

.con-content-subject {
  display: flex;
  gap: 20px;
  width: 100%;
}

.dropdown-task {
  display: flex;
  gap: 15px;
  margin-top: 40px;
}

.header-guru .header-guru-left .btn-add-guru p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 22px; */
  color: #ffffff;
}

.dropdown-task-murid .switch-container {
  width: max-content;
  display: flex;
  gap: 2px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 5px;
  border-radius: 10px;
  background-color: var(--color-sekunder);
}

#btn-materiKbm,
#btn-tugasKbm {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  padding: 5px 43px;
  border-radius: 5px;
  background-color: var(--color-sekunder);
  color: var(--color-aksen);
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

#btn-materiKbm.activeDetailKbm,
#btn-tugasKbm.activeDetailKbm {
  background-color: var(--color-aksen);
  color: var(--color-primer);
  /* transition-delay: 0.9s; */
}

.search-box {
  display: flex;
  align-items: center;
  width: 300px;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  /* padding: 8px; */
  height: 50px;
}

.search-box input[type="text"] {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 10px;
}

.search-box button[type="submit"] {
  background-color: #008cba;
  color: #fff;
  border: none;
  border-radius: 5px;
  /* padding: 8px; */
  margin-left: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.con-material {
  margin-top: 30px;
  margin-bottom: 100px;
}

.card-material {
  padding: 15px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid #e3e3e3;
  background: var(--color-primer);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}

.indiecator-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon-indie {
  /* background: rgba(221, 221, 221, 1); */
  /* padding: 20px; */
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
}

.material-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  /* line-height: 22px; */
  color: var(--color-aksen);
}

.teacher-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 30px; */
  color: #797979;
}

.indiecator-right {
  display: flex;
  gap: 50px;
}

.icon-navigate {
  color: var(--color-aksen);
}

.time-upload {
  margin-right: 80px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #797979;
}

.deadline-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-right: 50px;
}

@media only screen and (max-width: 945px) {
  .con-content-subject {
    display: grid;
  }

  .content-subject {
    width: 100%;
  }

  .content-subject-2 {
    width: 100%;
    /* justify-content: center; */
    /* align-items: center; */
  }

  .material-name {
    font-size: 15px;
  }
}

.dropdown-task-murid{
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 30px;
}