@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

:root {
    --color-primer: #FFFFFF;
    --color-aksen: #2A93D5;
    --color-sekunder: #D8F0FF;
}

.header-matapelajaran {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-add-matapelajaran {
    display: flex;
    background: var(--color-aksen);
    color: var(--color-primer);
    border: none;
    padding: 13px 35px;
    width: 200px;
    height: 50px;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    gap: 5px;
}

.btn-add-matapelajaran:hover {
    background: #1D77AF;
}

.header-matapelajaran-left {
    display: flex;
    gap: 15px;
}

.header-matapelajaran .header-matapelajaran-left .btn-add-matapelajaran p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 22px; */
    color: #FFFFFF;
}

.detail-jumlah-matapelajaran {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #2A93D5;
}

/*  */

.search-box {
    display: flex;
    align-items: center;
    width: 300px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
}

.search-box input[type="text"] {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 10px;
}

.search-box button {
    background-color: #008CBA;
    color: #fff;
    border: none;
    border-radius: 5px;
    /* padding: 8px; */
    width: 40px;
    height: 40px;
    margin-left: 8px;
    cursor: pointer;
}

/*  */

.content-matapelajaran {
    margin-top: 30px;
}

.con-card-matapelajaran {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

@media screen and (max-width: 650px) {
    .con-card-matapelajaran {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (min-width: 1020px) {
    .con-card-matapelajaran {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 1180px) {
    .con-card-matapelajaran {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1640px) {
    .con-card-matapelajaran {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (min-width: 2030px) {
    .con-card-matapelajaran {
        grid-template-columns: repeat(5, 1fr);
    }
}

.hover-card-matapelajaran {
    cursor: pointer;
    position: absolute;
    background: rgba(0, 0, 0, 0.25);
    opacity: 1;
    transition: 0.5s;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hover-card-matapelajaran .con-btn-card-matapelajaran {
    display: flex;
    gap: 15px;
}

.hover-card-matapelajaran .con-btn-card-matapelajaran .btn-edit-matapelajaran {
    background: #FFF625;
}

.hover-card-matapelajaran .con-btn-card-matapelajaran .btn-edit-matapelajaran:hover {
    background: #959514;
}

.hover-card-matapelajaran .con-btn-card-matapelajaran .btn-delete-matapelajaran {
    background: #BC2525;
    color: #fff;
}

.hover-card-matapelajaran .con-btn-card-matapelajaran .btn-delete-matapelajaran:hover {
    background: #7F1B1B;
}

.hover-card-matapelajaran .con-btn-card-matapelajaran button {
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    /* padding: 13px; */
    cursor: pointer;
}

.card-matapelajaran {
    border-radius: 20px;
    max-width: 405px;
    position: relative;
}

.image-card-matapelajaran {
    width: 100%;
    height: auto;
}

.content-card-matapelajaran {
    display: flex;
    /* justify-content: space-between; */
    /* transform: translateY(-300%); */
    position: absolute;
    top: 0;
    padding-left: 30px;
    width: 100%;
}

.card-matapelajaran-left {
    margin-top: 30px;
}

.mata-pelajaran-guru {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: var(--color-primer);
}

.nama-guru-matapelajaran {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: var(--color-primer);
}

.kelas-matapelajaran {
    right: 0;
    position: absolute;
    padding: 4px 18px;
    background: #4B4B4B;
    height: max-content;
    border-radius: 0px 7px 0px 10px;
    color: var(--color-primer);
}