.con-content-history-counseling {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 945px) {
  .con-content-history-counseling {
    grid-template-columns: repeat(1, 1fr);
  }
}

.content-history-promise-counseling {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  border: 1px solid #e3e3e3;
  border-radius: 20px;
  width: 100%;
  height: max-content;
  padding: 20px;
}

.text-promise-counseling {
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 500;
  color: #4b4b4b;
}

.card-promise-counseling {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  margin-top: 20px;
  padding: 15px;
}

.header-profile-teacher-bk {
  display: flex;
  justify-content: space-between;
}

.profile-teacher-bk {
  display: flex;
  align-items: center;
}

.icon-option-promise-counseling {
  color: #4b4b4b;
  cursor: pointer;
  float: right;
}

.topics-counseling {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  color: #4b4b4b;
  margin-top: 10px;
}

.topics-history-counseling {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  color: #797979;
  margin-top: 10px;
}

.information-promise-counseling {
  display: flex;
  margin-top: 15px;
  color: var(--color-aksen);
}

.information-promise-history-counseling {
  display: flex;
  margin-top: 15px;
  color: #797979;
}

.counseling-date {
  display: flex;
  align-items: center;
  gap: 5px;
}

.counseling-time {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}

.counseling-location {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 10px;
}
