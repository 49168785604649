@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap");

:root {
  --color-primer: #ffffff;
  --color-aksen: #2a93d5;
}

.loading-screen{
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 500;
  color: #2a93d5;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  padding-top: 280px;
}

.link-navigate{
  text-decoration: none;
}

#load {
  position:absolute;
  width:600px;
  height:36px;
  left:50%;
  top:40%;
  margin-left:-300px;
  overflow:visible;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  cursor:default;
}

#load div {
  position:absolute;
  width:20px;
  height:36px;
  opacity:0;
  font-family: 'Poppins';
  animation:move 2s linear infinite;
  -o-animation:move 2s linear infinite;
  -moz-animation:move 2s linear infinite;
  -webkit-animation:move 2s linear infinite;
  transform:rotate(180deg);
  -o-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  color:var(--color-aksen);
  font-size: 100px;
  font-weight: 500;
}

#load div:nth-child(2) {
  animation-delay:0.2s;
  -o-animation-delay:0.2s;
  -moz-animation-delay:0.2s;
  -webkit-animation-delay:0.2s;
}
#load div:nth-child(3) {
  animation-delay:0.4s;
  -o-animation-delay:0.4s;
  -webkit-animation-delay:0.4s;
  -webkit-animation-delay:0.4s;
}
#load div:nth-child(4) {
  animation-delay:0.6s;
  -o-animation-delay:0.6s;
  -moz-animation-delay:0.6s;
  -webkit-animation-delay:0.6s;
}
#load div:nth-child(5) {
  animation-delay:0.8s;
  -o-animation-delay:0.8s;
  -moz-animation-delay:0.8s;
  -webkit-animation-delay:0.8s;
}
#load div:nth-child(6) {
  animation-delay:1s;
  -o-animation-delay:1s;
  -moz-animation-delay:1s;
  -webkit-animation-delay:1s;
}
#load div:nth-child(7) {
  animation-delay:1.2s;
  -o-animation-delay:1.2s;
  -moz-animation-delay:1.2s;
  -webkit-animation-delay:1.2s;
}

@keyframes move {
  0% {
    left:0;
    opacity:0;
  }
  35% {
    left: 41%; 
    -moz-transform:rotate(0deg);
    -webkit-transform:rotate(0deg);
    -o-transform:rotate(0deg);
    transform:rotate(0deg);
    opacity:1;
  }
  65% {
    left:59%; 
    -moz-transform:rotate(0deg); 
    -webkit-transform:rotate(0deg); 
    -o-transform:rotate(0deg);
    transform:rotate(0deg); 
    opacity:1;
  }
  100% {
    left:100%; 
    -moz-transform:rotate(-180deg); 
    -webkit-transform:rotate(-180deg); 
    -o-transform:rotate(-180deg); 
    transform:rotate(-180deg);
    opacity:0;
  }
}

@-moz-keyframes move {
  0% {
    left:0; 
    opacity:0;
  }
  35% {
    left:41%; 
    -moz-transform:rotate(0deg); 
    transform:rotate(0deg);
    opacity:1;
  }
  65% {
    left:59%; 
    -moz-transform:rotate(0deg); 
    transform:rotate(0deg);
    opacity:1;
  }
  100% {
    left:100%; 
    -moz-transform:rotate(-180deg); 
    transform:rotate(-180deg);
    opacity:0;
  }
}

@-webkit-keyframes move {
  0% {
    left:0; 
    opacity:0;
  }
  35% {
    left:41%; 
    -webkit-transform:rotate(0deg); 
    transform:rotate(0deg); 
    opacity:1;
  }
  65% {
    left:59%; 
    -webkit-transform:rotate(0deg); 
    transform:rotate(0deg); 
    opacity:1;
  }
  100% {
    left:100%;
    -webkit-transform:rotate(-180deg); 
    transform:rotate(-180deg); 
    opacity:0;
  }
}

@-o-keyframes move {
  0% {
    left:0; 
    opacity:0;
  }
  35% {
    left:41%; 
    -o-transform:rotate(0deg); 
    transform:rotate(0deg); 
    opacity:1;
  }
  65% {
    left:59%; 
    -o-transform:rotate(0deg); 
    transform:rotate(0deg); 
    opacity:1;
  }
  100% {
    left:100%; 
    -o-transform:rotate(-180deg); 
    transform:rotate(-180deg); 
    opacity:0;
  }
}

.header-task-student {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-task-student-left {
  display: flex;
  gap: 15px;
}

.header-task-student .header-task-student-left p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  /* line-height: 22px; */
  color: #ffffff;
}

.content-task {
  margin-top: 30px;
}

.card-task {
  padding: 15px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid #e3e3e3;
  background: var(--color-primer);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
}

.indiecator-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon-indie-information {
  /* background: #fff; */
  /* padding: 20px; */
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
}

.title-indie-information {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  /* line-height: 22px; */
  color: #4b4b4b;
}

.value-indie-information {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 30px; */
  color: #797979;
}

.indiecator-right {
  display: flex;
  gap: 50px;
}

.icon-navigate {
  color: var(--color-aksen);
}

.time-upload {
  margin-right: 80px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #797979;
}

.deadline-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-right: 50px;
}
